import React from 'react';
import { PrimaryButton } from "../Button";
import { Eval403 } from "../Assets";
import { Row, Col } from 'antd';
import "./style.scss";

const ConsoleEndPoint = `https://console.${process.env.REACT_APP_MAIN_DOMAIN}`
export const AccessRestricted = (props) => {
  const handleContact = () => {
      window.location.assign(ConsoleEndPoint);
  };
  return (
    <div className='full w-100 h-100 col access-restricted'>
      <Row container className="h-100 w-100">
        <Col item xs={12} sm={12} md={12} lg={12} >
          <div className='col oval-container text-container'>
            <div className='exo2 clang'>Access <span className='txt'>Restricted!</span></div>
            <div className='exo2 txt-info'>
              <p className='exo2'>This page got a little too secure & locked itself out...<br/>
              And also you fo not have access to it!</p>
              <p className='exo2'>How about trying something else & <span onClick={props.onHome} className='c1155CC'>go back</span> to freedom!</p>
              <p className='exo2 last-line'>Here are some helpful options instead...</p>
            </div>
            <ul className='options-list'>
              <li className='mb-1'>
                <PrimaryButton color='#1155CC' className='exo2 back-home' variant='lite' label='Go Home' onClick={props.onHome} />
              </li>
              <li className='mb-1'>
                <PrimaryButton color='#1155CC' className='exo2 back-home' variant='lite' label='Contact-Organiser' onClick={handleContact} />
              </li>
              <li className=''>
                <PrimaryButton color='#1155CC' className='exo2 back-home' variant='lite' label='Get Help'/>
              </li>
            </ul>
          </div>
        </Col>
        <Eval403 alt='Oval-Not-Found' className='oval-404' />
      </Row>
    </div>
  )
}
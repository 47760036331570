import React from "react";
import { LinkButton } from "../Button";
import { Breadcrumb } from 'antd';
import "./style.scss"

export const PagePath = (props) => {
  const { paths } = props;
  const getItems = () => {
    let items = Array.isArray(paths) ? paths : []
    return items.map((_, i) => {
      let path = { ..._ },
        labelClassName = `f14 reg c1133CC ${_.labelClassName || ''}`
      if (i !== (items.length - 1)) {
        path.title = (
          <LinkButton
            variant='lite'
            href={_.href}
            to={_.to}
            label={_.title}
            labelClassName={labelClassName}
          />
        )
      } else {
        path.title = <span className={labelClassName}>{_.title}</span>
      }
      return path
    })
  }
  return (
    <Breadcrumb
      items={getItems()}
    />
  )
}
import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "antd";
import { EditOutlined, CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from "react-redux";
import { getCurrentTask, taskActions, isDayjsObject } from "../../store";
import { Button, PrimaryButton, MenuButton, Form, IconAdd } from "../../components";
import { RemoveTaskResponder, AddTaskResponder, TransferTaskDept } from "./Dialogs";
import FormFields from "./FormFields.json";
import { InfoIcon } from './Helpers';

const Properties = [
  { label: "Department:", attribute: 'department', canEdit: true },
  { label: "Source:", attribute: 'source', },
  { label: "Type:", attribute: 'activity_type', },
  { label: (<span> Plan: <span style={{marginLeft:'6px'}}><InfoIcon placement="top" overlayClassName='custom-tooltip2'/> </span> </span>), attribute: 'plan_date_str' },
  { label: "Due:", attribute: 'due_date_str', canEdit: true },
  { label: "Created:", attribute: 'created_at', },
  { label: "Updated:", attribute: 'updated_at', },
  { label: "Closure:", attribute: 'closure_date_str', },
  { label: "Aging:", attribute: 'aging', },
  // { label: "Frequency:", attribute: 'repetition_occurs', },
]
export const TaskMenu = () => {
  const dispatch = useDispatch();
  const task = useSelector(getCurrentTask)
  const [state, setState] = React.useState({
    responderToRemove: null,
    responderToAdd: false,
    editDueDate: false,
    transferDept: false,
    duedate: null,
    showMenu: true, // New state to control visibility of the TaskMenu
  })
  const updateState = (newValues) => {
    setState((_) => ({ ..._, ...newValues }))
  }
  const handleRemoveResponder = (responder, e) => {
    e && e.stopPropagation && e.stopPropagation();
    updateState({ responderToRemove: responder })
  }
  const handleEdit = (attribute, e) => {
    e && e.stopPropagation && e.stopPropagation();
    if (attribute === Properties[0].attribute) {
      updateState({ transferDept: true, showMenu: false })
    } else if (attribute === Properties[4].attribute) {
      updateState({ editDueDate: true, duedate: task.due_date })
    }
  }
  const handleSaveDueDate = () => {
    if (isDayjsObject(state.duedate)) {
      let updatedVal = { due_date: state.duedate.toISOString() }
      let comment = 'Due Date updated.'
      dispatch(taskActions.updateTask({ taskId: task.id, updatedVal, comment }))
    }
    updateState({ editDueDate: false, duedate: '' })
  }
  const capitalizeFirstLetter = (text) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  
  const duedate = (
    <React.Fragment>
      <div className="col exo2 f16 bold-600 label-sec">{Properties[4].label}</div>
      <div className="row f-rest h-btn">
        <Form
          hideErrorMsg
          className="col due-date-form"
          formData={{ due_date: state.duedate }}
          Fields={FormFields.DueDate}
          onChange={(e) => {
            updateState({ duedate: e.target.value })
          }} />
        <Button
          type='text'
          onClick={handleSaveDueDate}
          disabled={!Boolean(state.duedate)}
          icon={<CheckCircleOutlined style={{ color: '#0133CC' }} />}
        />
      </div>
    </React.Fragment>
  )
  const content = (
    <div
      onClick={(e) => {
        e && e.stopPropagation && e.stopPropagation();
      }}
      className="col task-menu-content">
      {
        Properties.map((_) => {
          return (
            <div className="row entry" key={_.attribute}>
              {
                (_.attribute === Properties[4].attribute && state.editDueDate) ?
                  duedate
                  :
                  <React.Fragment>
                    <div className="col exo2 f16 bold-600 label-sec">{_.label}</div>
                    <div className="row f-rest h-btn">
                    <span className="f16 reg">{capitalizeFirstLetter(task[_.attribute])}</span>
                    {
                        Boolean(_.canEdit) && !state.editDueDate &&
                        <Button
                          onClick={handleEdit.bind(null, _.attribute)}
                          icon={<EditOutlined style={{ color: '#1133CC' }} />}
                        />
                      }
                    </div>
                  </React.Fragment>
              }
            </div>
          )
        })
      }
      <div className="row w-100 entry v-start">
        <div className="col label-sec">
          <span className="exo2 f16 bold-600">Responder:</span>
          <PrimaryButton
            label='Add'
            className='add-res v-ctr h-ctr'
            labelClassName='bold-600 exo2 f14 line-12'
            onClick={() => updateState({ responderToAdd: true, showMenu: false })} // Hide TaskMenu when Add Responder is clicked
            icon={<IconAdd className='cFFF f16' />}
          />
        </div>
        <div className="col res-list f-rest oy-auto">
          {
            Array.isArray(task.task_responders) && task.task_responders.map(({ responder }, i) => {
              return (
                <div className="row w-100">
                  <span className="f14 reg line-12  c1133CC">{responder._listname}</span>
                  {
                    task.task_responders.length > 1 &&
                    <Button
                      type='text'
                      className='bgTrans'
                      onClick={handleRemoveResponder.bind(null, responder)}
                      icon={<CloseCircleOutlined style={{ color: '#F00' }} />}
                    />
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
  return (
    <React.Fragment>
      <Dropdown
        overlayClassName="task-menus"
        placement="bottomRight"
        menu={{ items: (state.showMenu) ? [{ key: '0', label: content }] : [] }}
        trigger={["click"]}
      >
        <MenuButton iconColor="#1133CC" onClick={(e) => e.preventDefault()} />
      </Dropdown>
      <RemoveTaskResponder
        responder={state.responderToRemove}
        open={(Boolean(state.responderToRemove))}
        onClose={handleRemoveResponder.bind(null, null)}
      />
      <AddTaskResponder
        open={state.responderToAdd}
        onClose={updateState.bind(null, { responderToAdd: false, showMenu: true })} // Show TaskMenu when modal is closed
      />
      <TransferTaskDept
        open={state.transferDept}
        onClose={updateState.bind(null, { transferDept: false, showMenu: true })}
      />
    </React.Fragment>
  )
}
export const ResponderCell = (text, task) => {
  const responders = task.task_responders || [];
  const getItems = () => {
    return responders.slice(1).map((responder, i) => {
      return { key: i, label: <span className="f14 c000 reg">{responder.responder._listname}</span> }
    })
  }
  return (
    <div className="row w-100 h-btn">
      <span className="f16 c00085 reg no-wrap">{responders[0] ? responders[0]?.responder?._listname : ''} </span>
      {
        Array.isArray(responders) && responders.length > 1 &&
        <Dropdown
          placement="bottom"
          menu={{ items: getItems() }}
          trigger={['click']}>
          <Button
            className='btn-text btn-responder'
            labelClassName='f12 c1133CC'
            onClick={(e) => e.preventDefault()}
            label={`+${responders.length - 1}`} />
        </Dropdown>
      }
    </div>
  )
}

export const TitleCell = ({ text = '', maxChars = 64 }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldTruncate, setShouldTruncate] = useState(false);
  const [visibleChars, setVisibleChars] = useState(maxChars);
  const textRef = useRef(null);
  const fullTextRef = useRef(null);

  const truncateText = (fullText, charLimit) => {
    if (!shouldTruncate || isExpanded || fullText.length <= charLimit) {
      return fullText;
    }
    const truncated = fullText.substring(0, charLimit);
    const lastSpace = truncated.lastIndexOf(' ');

    if (lastSpace === -1) {
      return truncated + '...';
    }
    return truncated.substring(0, lastSpace) + '...';
  };
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  useEffect(() => {
    const updateVisibleChars = () => {
      if (textRef.current && fullTextRef.current) {
        const containerWidth = textRef.current.offsetWidth;
        const fullTextWidth = fullTextRef.current.scrollWidth;

        const avgCharWidth = 7; // Average width of a character in pixels
        const newVisibleChars = Math.floor(containerWidth / avgCharWidth);

        setVisibleChars(Math.min(maxChars, newVisibleChars));
        setShouldTruncate(fullTextWidth > containerWidth);
      }
    };
    updateVisibleChars();
    window.addEventListener('resize', updateVisibleChars);

    return () => {
      window.removeEventListener('resize', updateVisibleChars);
    };
  }, [maxChars]);

  const displayText = truncateText(text, visibleChars);

  return (
    <div ref={textRef} className="title-cell-container f16">
      <div
        ref={fullTextRef}
        className={`title-cell ${isExpanded ? 'expanded' : ''}`}
        onClick={handleToggle}
        style={{ cursor: shouldTruncate ? 'pointer' : 'default' }}
      >
        {displayText}
      </div>
    </div>
  );
};

import { Provider } from 'react-redux';
import { createAppStore } from "./store";
import ArcRouter from "./routers";
import { App } from 'antd';
import './App.scss';

function ArcModuleApp() {
  return (
    <App className="col w-100 h-100 o-hide">
      <Provider store={createAppStore()}>
        <ArcRouter />
      </Provider>
    </App>
  );
}

export default ArcModuleApp;

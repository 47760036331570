import { getDateInFullYearFormat } from "../../store"
import React, { useEffect } from "react";
import { Select, Space } from "antd";
import { CaretDownOutlined } from '@ant-design/icons';


export function TableTopBar(props) {
    const { title, handleFieldChange, options, selectedDateId} = props;
    return (
        <div>
            <div className="d-flex">
                <div className="f18 exo2 bold c238787 title flex-grow-1" style={{ marginBottom: '12px' }} >
                    <div className="exo2">{title}</div>
                </div>
                <div className="" >
                    <div>
                        <Space wrap className='mb1'>
                            <Select
                                style={{ width: 200 }}
                                className='f7 custom-border custom-select'
                                onChange={handleFieldChange}
                                options={options.map((option) => {
                                    return { value: option.id, label: option.label }
                                })}
                                value={selectedDateId}
                                suffixIcon={<CaretDownOutlined style={{pointerEvents: 'none'}}/>}
                            />
                        </Space>
                        <div className="d-flex h-end" style={{ marginTop: '-12px' }}  >
                            <div className='exo2 f12' style={{ marginRight: '5px' }}>Stats as on </div> <b className="exo2 f12"> {getDateInFullYearFormat()}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
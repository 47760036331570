import React, { useEffect } from "react";
import { DeptVsComplianceStats } from "./DeptVsComp";
import { PriorityVsComplianceStats } from "./PriorVsComp";
import { DeptVsPriorityStats } from "./DeptVsPrior";
import { useDispatch, useSelector } from "react-redux";
import { getArcStats, taskActions } from "../../../store";

export const TaskStatistics = () => {
  const dispatch = useDispatch()
  const arcStats = useSelector(getArcStats)
  useEffect(() => {
    let payload = {
      "date_range_type": "last_365_days",
      "start_date": "01-11-2023",
      "end_date": "20-11-2024",
      "stats_type": "all_stats"
    };
    try {
      dispatch(taskActions.fetchArcStats(payload))
    } catch (error) {
      console.error('Error fetching data from server:', error);
    }
  },[])
  return (
    <div className="h-100 oy-auto">
      <div className="mt-2"><DeptVsComplianceStats arcStats={arcStats} /></div>
      <div className="mt-4"><PriorityVsComplianceStats arcStats={arcStats} /></div>
      <div className="mt-4"><DeptVsPriorityStats arcStats={arcStats} /></div>
    </div>
  )
}
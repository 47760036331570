import { useEffect, useState } from "react";
import { TableComponent } from "../../StatsTable";
import { useDispatch, useSelector } from "react-redux";
import { getPriorityStats, getCurrentUser, getDepartments, taskActions } from "../../../store";

const Field = { attribute: 'dateRange', type: 'select' }

const initPriorityRows = [
  { priority: 'Critical', total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, labelClassName: 'Critical' },
  { priority: 'High', total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, labelClassName: 'High' },
  { priority: 'Medium', total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, labelClassName: 'Medium' },
  { priority: 'Low', total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, labelClassName: 'Low' },
  { priority: 'Minimal', total: 0, complete: 0, inprogress: 0, pending: 0, other: 0, labelClassName: 'Minimal' },
]

const initRowClassName = {
  'Critical': 'Critical',
  'High': 'High',
  'Medium': 'Medium',
  'Low': 'Low',
  'Minimal': 'Minimal',
  'Default': 'Default',
  'Emergency': 'Emergency',
  'Urgent': 'Urgent',
}
export const PriorityVsComplianceStats = (props) => {
  const arcStats = props
  const [responseData, setResponseData] = useState({});
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUser);
  const departments = useSelector(getDepartments);
  const priorityStats = useSelector(getPriorityStats)

  const deptInitialRows = [...initPriorityRows]
  const PriorityColumns = [
    { label: '', attribute: 'priority', headerClassName: 'txt-left', isLabel: true, width: '7%' },
    { label: 'Max Aging<br/>(Days)', attribute: 'max_aging', width: '8%', headerClassName: 'bgDDFFFF' },
    { label: 'Avg Aging<br/>(Days)', attribute: 'avg_aging', width: '8%', headerClassName: 'bgDDFFFF' },
    { label: 'Total', attribute: 'Total', headerClassName: 'bgDDFFFF', width: '8%' },
    { label: 'Complete', attribute: 'Complete', headerClassName: '', width: '8%', colorIndicator: true },
    { label: 'Started', attribute: 'InProgress', headerClassName: '', width: '8%', colorIndicator: true },
    { label: 'Pending', attribute: 'Pending', headerClassName: '', width: '8%', colorIndicator: true },
    { label: 'Other', attribute: 'Other', headerClassName: '', width: '8%', colorIndicator: true },
  ]

  const [tableData, setTableData] = useState([...deptInitialRows]);

  const getDataFromServer = async (dateRange) => {
    let payload = {
      "date_range_type": "last_365_days",
      "start_date": "01-11-2023",
      "end_date": "20-11-2024",
      "stats_type": "priority_stats"
    };
    if (dateRange && dateRange.id) {
      payload.date_range_type = dateRange.id;
    }
    try {
      dispatch(taskActions.fetchArcStats(payload))
    } catch (error) {
      console.error('Error fetching data from server:', error);
    }
  };
  useEffect(() => {
    // Prioritize priorityStats if available, otherwise use arcStats
    if (priorityStats && Object.keys(priorityStats).length > 0) {
      setResponseData(priorityStats);
    } else if (arcStats?.priority_status_list) {
      setResponseData(arcStats.priority_status_list);
    }
  }, [arcStats, priorityStats]);

  useEffect(() => {
    if (responseData && user && departments) {
      let tempTableData = getDataFromResponse(responseData, user, departments);
      setTableData([...tempTableData]);
    }
  }, [responseData, user, departments]);

  const DeptTableID = 'arc-priority-table';

  return (
    <>
      <TableComponent
        title='Priority-wise Compliance Status'
        topBarField={Field}
        rows={tableData}
        Columns={PriorityColumns}
        tableField={Field}
        tableID={DeptTableID}
        tableClassName='arc-stats-tbl'
        onDateChange={getDataFromServer}
      />
    </>
  );
}

const getDataFromResponse = (res, user, departments) => {
  if (!res) return [];

  const brandname = user.ent_org.brandname;
  let totalRow = {
    priority: brandname,
    max_aging: 0,
    avg_aging: 0,
    Total: 0,
    Complete: 0,
    InProgress: 0,
    Pending: 0,
    Other: 0,
    labelClassName: 'bgTotal'
  };

  let output = [];
  const responseData = {...res};
  console.log({responseData})
  // if (responseData.Default && responseData.Medium) {
  //   const mediumData = responseData.Medium;

  //   // Adding values from Default to Medium
  //   Object.entries(responseData.Default).forEach(([key, value]) => {
  //     if (key === 'max_aging') {
  //       mediumData.max_aging = Math.max(mediumData.max_aging || 0, value);
  //     } else if (key === 'avg_aging') {
  //       mediumData.avg_aging = mediumData.avg_aging ? 
  //         (mediumData.avg_aging + value) / 2 : value; // Average both avg_aging values
  //     } else {
  //       mediumData[key] = (mediumData[key] || 0) + value;
  //     }
  //   });
  // }

  // Priorities to display in the table (excluding Emergency and Urgent)
  const displayPriorities = ['Critical', 'High', 'Medium', 'Low', 'Minimal'];
  const allPriorities = ['Critical', 'High', 'Medium', 'Low', 'Minimal', 'Default', 'Emergency', 'Urgent']; // All priorities for calculation

  const outputArray = allPriorities.map(priorityKey => {
    let row = {
      Total: 0,
      Complete: 0,
      InProgress: 0,
      Pending: 0,
      Other: 0,
      max_aging: 0,
      avg_aging: 0,
      labelClassName: initRowClassName[priorityKey] || 'Default',
      priority: priorityKey
    };

    const priorityData = responseData[priorityKey];
    if (priorityData) {
      for (const [statusKey, value] of Object.entries(priorityData)) {
        if (statusKey === 'Pending') {
          row[statusKey] = value;
          row['Total'] += value;
        } else if (['Closed', 'Duplicate', 'Open', 'Future'].includes(statusKey)) {
          row['Other'] += value; // Include Closed, Duplicate, Open, Future in Other
          row['Total'] += value;
        } else if (statusKey !== 'total' && statusKey !== 'max_aging' && statusKey !== 'avg_aging') {
          // Handle any other status that is not explicitly defined
          row['Other'] += value;
          row['Total'] += value;
        }

        if (statusKey === 'max_aging') {
          row['max_aging'] = Math.max(row['max_aging'], value);
        }
        if (statusKey === 'avg_aging' && value > 0) {
          row['avg_aging'] = value; // Assigning directly since avg_aging is unique per priority
        }
      }
    }

    return row;
  });

  // Aggregate totalRow values, but filter for display
  outputArray.forEach(row => {
    if (!['Emergency', 'Urgent'].includes(row.priority)) { // Exclude Emergency and Urgent from avg_aging calculation
      totalRow['avg_aging'] += row['avg_aging'];
    }

    // Always include in totals calculation
    totalRow['Total'] += row['Total'];
    totalRow['Complete'] += row['Complete'] || 0;
    totalRow['InProgress'] += row['InProgress'] || 0;
    totalRow['Pending'] += row['Pending'];
    totalRow['Other'] += row['Other'];
    totalRow['max_aging'] = Math.max(totalRow['max_aging'], row['max_aging']);
  });

  totalRow['avg_aging'] = Math.ceil(totalRow['avg_aging'] / 8); // Average calculated over 8 department categories
  console.log({outputArray})

  // Only include displayPriorities in the output
  output = [totalRow, ...outputArray.filter(row => displayPriorities.includes(row.priority))];

  // Replace zeros with '-'
  output.forEach(row => {
    Object.keys(row).forEach(key => {
      if (key !== 'priority' && key !== 'labelClassName' && (row[key] === 0 || row[key] === '0')) {
        row[key] = '-';
      }
    });
  });

  return output;
}





import React from "react";
import { RightSquareOutlined, SwapOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { getTaskDeptResponders, taskActions, getCurrentTask, getFilteredActiveUsers, getDepartments, commonActions, getUserById } from "../../store";
import { Modal, Form } from "../../components";
import FormFields from "./FormFields.json";

const initialState = { department: null, step: 1, responder: null, removeAllResponders: true, taskId: '' }

const RemoveResponderConfirm = (props) => {
  const { open, responder } = props;
  const dispatch = useDispatch();
  const task = useSelector(getCurrentTask)
  const handleRemove = () => {
    const comment = `<span class="f16">Responder Removed: <b>${responder._listname}</b>.</span>`;
    const payload = { task_id: task.id, responder: { ent_usr_id: responder.id }, comment }
    dispatch(taskActions.removeTaskResponder(payload));
    props.onConfirm && props.onConfirm()
  }
  React.useEffect(() => {
    if (open) {
      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          props.onClose();
        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [open]);
  return (
    <Modal
      width='480px'
      open={open}
      onClose={props.onClose}
      actions={[
        { type: "cancel", label: 'Remove', ClassName: 'f14 exo2 bold-600', danger: true, onClick: handleRemove },
        { type: "primary", isCancel: true, onClick: props.onClose },
      ]}
      title={<span className="bold-600 f20 line-12 cF00 exo2">Confirm Removal!</span>}>
      {
        Boolean(responder) &&
        <div className="col w-100 f16 reg line-12 c00085">
          {responder._name} WILL NOT be able to view or contribute towards this Task anymore!<br /><br />
          <div>Confirm that you want continue with removal.</div>
        </div>
      }
    </Modal>
  )
}

export const RemoveTaskResponder = (props) => {
  const { open, responder } = props
  const [showConfirm, setShowConfirm] = React.useState(false);
  React.useEffect(() => {
    if (open) {
      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          setShowConfirm(true);
        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [open]);
  const handleConfirm = () => {
    setShowConfirm(false);
    props.onClose && props.onClose()
  }
  return (
    <React.Fragment>
      <Modal
        width='330px'
        open={open}
        onClose={props.onClose}
        actions={[
          { label: 'Remove', ClassName: ' f14 exo2 bold-600', danger: true, type: "cancel", onClick: () => setShowConfirm(true) }
        ]}
        title={<span className="bold-600 f16 line-12">{responder && responder._name}</span>}>
        {
          Boolean(responder) &&
          <div className="col w-100 f12 reg ">
            <span className="c00045 line-22">{responder.designation}, {responder.department_name}</span>
            <span className="c000 line-22">{responder.mobile}</span>
            <span className="c00085 line-22">{responder.email}</span>
          </div>
        }
      </Modal>
      <RemoveResponderConfirm
        open={showConfirm}
        responder={responder}
        onConfirm={handleConfirm}
        onClose={() => setShowConfirm(false)}
      />
    </React.Fragment>
  )
}

export const AddTaskResponder = (props) => {
  const { open, onClose } = props;
  const [responder, setResponder] = React.useState('');
  const task = useSelector(getCurrentTask)
  const users = useSelector(getFilteredActiveUsers.bind(null, task.task_responders));
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (open) {
      setResponder('');
      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
          if (responder) {
            handleConfirm();
          }
        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [open])
  const getOptions = () => {
    return users;
  }
  const handleResponderChange = (e) => {
    setResponder(e.target.value)
  }
  const handleConfirm = () => {
    if (task?.id && responder?.id) {
      let user = getUserById(users, responder.id)
      const comment = `<span class="f16">New Responder added: <b>${user._listname}</b></span>`;
      const payload = { task_id: task.id, responder: { ent_usr_id: responder.id }, comment }
      dispatch(taskActions.addTaskResponder(payload));
      onClose && onClose()
    }
  }
  return (
    <React.Fragment>
      <Modal
        width='480px'
        className='add-responder'
        open={open}
        onClose={onClose}
        actions={[
          { label: 'Cancel', type: "cancel", ClassName: ' f14 exo2 bold-600', onClick: onClose },
          { label: 'Confirm', type: "primary", ClassName: ' f14 exo2 bold-600', disabled: !Boolean(responder), onClick: handleConfirm },
        ]}
        title={<span className="bold-600 f20 exo2 line-12 c238787">Add Responder?</span>}>
        <div className="col w-100">
          <span className="f16 line-22 c00085 reg">The Responder will be able to access and edit this Task.</span>
          <Form
            className="row"
            formData={{ responder }}
            Fields={FormFields.AddResponder}
            getOptions={getOptions}
            onChange={handleResponderChange} />
        </div>
      </Modal>
    </React.Fragment>
  )
}

export const TransferTaskDept = (props) => {
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const departments = useSelector(getDepartments);
  const task = useSelector(getCurrentTask);
  const deptResponders = useSelector(getTaskDeptResponders);
  const users = useSelector(getFilteredActiveUsers.bind(null, task.task_responders));
  const [state, setState] = React.useState({ ...initialState });

  React.useEffect(() => {
    if (open) {
      dispatch(commonActions.fetchTaskDeptResponders());
      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          handleClose();
        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [open]);

  const updateState = (val) => {
    setState((_) => ({ ..._, ...val }));
  };

  const handleClose = () => {
    updateState({ ...initialState });
    onClose && onClose();
  };

  const getOptions = (attribute) => {
    let options = [];
    switch (attribute) {
      case "responder":
        options = users;
        break;
      case "department":
        options = departments.filter(({ id }) => id !== task.department);
        break;
      default:
        options = [];
        break;
    }
    return options;
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    updateState({ [name]: value });
  };

  const handleNext = () => {
    if (state.department && isStep(1)) {
      let newState = { step: 2 };
      const deptResp = (deptResponders || []).find(({ department_name }) => department_name === state.department.id);

      if (deptResp && deptResp.responder) {
        const responder = deptResp.responder;
        const fullName = `${responder.firstname} ${responder.lastname}`;
        const label = `${fullName}, ${deptResp.department_name}`;

        newState.responder = {
          id: responder.id,
          label: label,
          _listname: fullName
        };
      }

      updateState(newState);
    }

    if (state.responder && isStep(2)) {
      updateState({ step: 3 });
    }

    if (isStep(3)) {
      const removeAllResponders = state.removeAllResponders;
      const addResponder = state.responder?.id;  // Use optional chaining to avoid errors
      let updatedVal = { department: state.department.id };
      let comment = `Department transferred from <b>${task.department}</b> to <b>${state.department.label}</b>.<br/>`;

      if (removeAllResponders && task.task_responders.length > 0) {
        let removedResText = (task.task_responders || []).map(({ responder }) => `<li><b>${responder?._listname || ''}</b></li>`).join("");  // Handle undefined
        comment += `Below users removed from responders.<ul>${removedResText}</ul>`;
      }
      
      if (addResponder) {
        let user = getUserById(users, addResponder);
        comment += `New Responder added: <b>${user?._listname || ''}</b>.`;  // Handle undefined
      }

      comment = `<span class='f16'>${comment}</span>`;
      dispatch(taskActions.updateTask({ taskId: task.id, updatedVal, addResponder, removeAllResponders, addResponder, comment }));
      handleClose();
    }
  };

  const isStep = (iStep) => iStep === state.step;

  const getActions = () => {
    const actions = [
      { label: 'Next', type: "cancel", ClassName: 'f14 exo2 bold-600', Icon: RightSquareOutlined, onClick: handleNext, disabled: getDisabled() },
      { label: 'Cancel', type: "primary", ClassName: 'f14 exo2 bold-600', isCancel: true, onClick: handleClose },
    ];
    if (isStep(3)) {
      actions[0].Icon = SwapOutlined;
      actions[0].label = "Transfer";
      actions[0].danger = true;
    }
    return actions;
  };

  const getDisabled = () => (
    (isStep(1) && !Boolean(state.department)) ||
    (isStep(2) && !Boolean(state.responder)) ||
    (isStep(3) && String(state.taskId).toLowerCase() !== String(task.task_id).toLowerCase())
  );

  return (
    <React.Fragment>
      <Modal
        width="480px"
        className="dept-trans"
        open={open}
        onClose={onClose}
        actions={getActions()}
        title={(
          <span className={`bold-600 f20 exo2 line-12 ${isStep(3) ? 'cF00' : 'c238787'}`}>
            Transfer Department?
          </span>
        )}
      >
        <div className="col w-100">
          {isStep(1) && (
            <React.Fragment>
              <span className="f16 line-22 c00085">Select the Department that you would like to transfer to…</span>
              <Form
                className="row"
                formData={{ department: state.department }}
                Fields={[FormFields.TrasferDept[0]]}
                getOptions={getOptions}
                onChange={handleFieldChange}
              />
            </React.Fragment>
          )}
          {isStep(2) && (
            <React.Fragment>
              <p className="f16 reg c00085 para">
                Default Responder defined for this Department is auto-selected. If field remains blank then a default Responder for this Department is not defined! <br /> <br />
                Search & select to assign this Task to any Responder.
              </p>
              <Form
                className="row"
                formData={{ responder: state.responder }}
                Fields={[FormFields.TrasferDept[1]]}
                getOptions={getOptions}
                onChange={handleFieldChange}
              />
            </React.Fragment>
          )}
          {isStep(3) && (
            <React.Fragment>
              <p className="f16 reg 00085 para">
                It will be difficult to reverse this action! <br />Are you sure you want to proceed?<br /><br />
                From: <span className="bold-700">{task.department}</span><br />
                To: <span className="bold-700">{state.department.label}</span><br />
              </p>
              <Form
                className="col"
                formData={{
                  taskId: state.taskId,
                  removeAllResponders: state.removeAllResponders
                }}
                direction="vertical"
                Fields={FormFields.TrasferDept.slice(2)}
                getOptions={getOptions}
                onChange={handleFieldChange}
              />
            </React.Fragment>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export const TaskStatusConfirm = (props) => {
  const { open, attribute, onClose, newValue, onConfirm } = props;
  const task = useSelector(getCurrentTask)
  const [note, setNote] = React.useState('')
  const [showCloseDialog, setShowCloseDialog] = React.useState(false)
  const handleConfirm = () => {
    if ((!showCloseDialog) && ((newValue?.label === 'Closed') || (newValue?.label === 'Complete'))) {
      setShowCloseDialog(true);
    }
    else {
      setNote('');
      onConfirm && onConfirm(note)
      setShowCloseDialog(false);
    }
  }
  const handleClose = () => {
    setNote('');
    onClose && onClose(note)
  }
  const getFields = () => {
    const field = { ...FormFields.StatusPriorityNote }
    if (attribute === 'priority') {
      field.placeholder = 'Example: The priority for this task needs to be increased because External Audit is due within two months.'
    }
    if (attribute === 'status') {
      field.placeholder = 'Example: This Task is marked as Remediated because all necessary actions have been taken.'
    }
    return [field]
  }
  React.useEffect(() => {
    if (open) {
      const handleKeyDown = (event) => {
        if (event.key === 'Enter' && note.length > 0) {
          handleConfirm();
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [open, note]);
  return (
    <>
      <Modal
        width='480px'
        className='dept-trans'
        open={open}
        onClose={handleClose}
        actions={[
          { label: 'Cancel', type: "cancel", ClassName: ' f14 exo2 bold-600', onClick: handleClose },
          { label: 'Confirm', type: "primary", ClassName: ' f14 exo2 bold-600', disabled: note.length === 0, onClick: handleConfirm },
        ]}
        title={<span className="bold-600 f20 exo2 line-12 c238787 caps">Change Task {attribute}?</span>}>
        <div className="col w-100">
          <p className="f16 reg para">
            This action will update the <span className="caps">{attribute}</span> for this Task <br /><br />
            From: <span className="bold-700">{task && task[attribute] && task[attribute].label}</span><br />
            To: <span className="bold-700">{newValue && newValue.label}</span><br /><br />
            Provide a note for Task History.
          </p>
          <Form
            className="row"
            direction='vertical'
            formData={{ note }}
            Fields={getFields()}
            onChange={(e) => setNote(e.target.value)} />
        </div>
      </Modal>
      {
        (showCloseDialog) &&
        <Modal
          width='480px'
          className='dept-trans'
          open={showCloseDialog}
          onClose={handleClose}
          actions={[
            { label: 'Cancel', type: "cancel", ClassName: ' f14 exo2 bold-600', onClick: () => { setShowCloseDialog(false) } },
            { label: 'Confirm', type: "primary", ClassName: ' f14 exo2 bold-600', onClick: handleConfirm },
          ]}
          title={<span className="bold-600 f20 exo2 line-12 c238787 caps">Close Task?</span>}>
          <div className="col w-100">
            <p className="f16 reg para">
              Closing a Task is difficult to re-open!<br />
              Today's date will be recorded as the Closure Date.
            </p>
          </div>
        </Modal>
      }
    </>
  )
}
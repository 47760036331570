import { useEffect, useState } from "react";
import { TableComponent } from "../../StatsTable";
import { useDispatch, useSelector } from "react-redux";
import { getDeptStats, getCurrentUser, getDepartments, taskActions } from "../../../store";

const Field = { attribute: 'dateRange', type: 'select' };

export const DeptVsComplianceStats = (props) => {
  const arcStats = props
  const [responseData, setResponseData] = useState({});
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUser);
  const departments = useSelector(getDepartments);
  const deptStats = useSelector(getDeptStats)
  const deptInitialRows = departments.map((deptObj) => {
    return (
      { 'department': deptObj.id, 'total': 0, 'complete': 0, 'inprogress': 0, 'pending': 0, 'other': 0 }
    )
  })

  deptInitialRows.unshift({
    'department': user.ent_org.brandname, 'total': 0, 'complete': 0, 'inprogress': 0, 'pending': 0, 'other': 0
  });

  const DeptColumns = [
    { label: '', attribute: 'department', width: '9%', headerClassName: 'txt-left', isLabel: true },
    { label: 'Max Aging<br/>(Days)', attribute: 'max_aging', width: '8%', headerClassName: 'bgDDFFFF' },
    { label: 'Avg Aging<br/>(Days)', attribute: 'avg_aging', width: '8%', headerClassName: 'bgDDFFFF' },
    { label: 'OVERALL', attribute: 'total', width: '8%', headerClassName: 'bgDDFFFF' },
    { label: 'Complete', attribute: 'complete', width: '8%', headerClassName: '', colorIndicator: true },
    { label: 'Started', attribute: 'inprogress', width: '8%', headerClassName: 'orange-f77a50', colorIndicator: true },
    { label: 'Pending', attribute: 'pending', width: '8%', headerClassName: '', colorIndicator: true },
    { label: 'Other', attribute: 'other', width: '8%', headerClassName: '', colorIndicator: true },
  ];

  const [tableData, setTableData] = useState([...deptInitialRows]);

  const getDataFromServer = async (dateRange) => {
    let payload = {
      "date_range_type": "last_365_days",
      "start_date": "01-11-2023",
      "end_date": "20-11-2024",
      "stats_type": "dept_stats"
    };
    if (dateRange && dateRange.id) {
      payload.date_range_type = dateRange.id;
    }
    try {
      dispatch(taskActions.fetchArcStats(payload))
    } catch (error) {
      console.error('Error fetching data from server:', error);
    }
  };
  useEffect(() => {
      if(deptStats && Object.keys(deptStats).length > 0){
        setResponseData(deptStats)
      }
      else{
        setResponseData(arcStats?.dept_status_list)
      }
  }, [arcStats, deptStats])

  useEffect(() => {
    if (responseData && user && departments) {
      let tempTableData = getDataFromResponse(responseData, user, departments);
      setTableData([...tempTableData]);
    }
  }, [responseData, user, departments]);

  const DeptTableID = 'arc-dept-table';

  return (
    <>
      <TableComponent
        title='Department-wise Compliance Status'
        topBarField={Field}
        rows={tableData}
        Columns={DeptColumns}
        tableField={Field}
        tableID={DeptTableID}
        tableClassName='arc-stats-tbl'
        onDateChange={getDataFromServer}
      />
    </>
  );
}

const getDataFromResponse = (res, user, departments) => {
  if (res) {
    let dept_compliance = [];
    const brandname = user.ent_org.brandname;
    let totalRow = { department: brandname, max_aging: 0, avg_aging: 0, total: 0, complete: 0, inprogress: 0, pending: 0, other: 0 };
    let departmentMap = {};

    for (const key in res) {
      let correctedKey = key.replace(/\s/g, ""); // Remove spaces from the key

      const normalizedKey = correctedKey.toLowerCase(); // Normalize the department name
      const deptStatus = res[key];
      const total = parseInt(deptStatus.total, 10) || 0;
      const complete = parseInt(deptStatus.Complete, 10) || 0;
      const pending = parseInt(deptStatus.Pending, 10) || 0;
      const inprogress = parseInt(deptStatus.InProgress, 10) || 0;
      const other = total - (complete + inprogress + pending);
      const maxAging = parseInt(deptStatus.max_aging, 10) || 0;
      const avgAging = parseInt(deptStatus.avg_aging, 10) || 0;

      if (departmentMap[normalizedKey]) {
        // If department already exists, add values to existing department
        departmentMap[normalizedKey].total += total;
        departmentMap[normalizedKey].complete += complete;
        departmentMap[normalizedKey].inprogress += inprogress;
        departmentMap[normalizedKey].pending += pending;
        departmentMap[normalizedKey].other += other;
        departmentMap[normalizedKey].max_aging = maxAging;
        departmentMap[normalizedKey].avg_aging = avgAging;
      } else {
        // If department does not exist, create a new entry
        departmentMap[normalizedKey] = {
          department: correctedKey,
          total: total,
          complete: complete,
          inprogress: inprogress,
          pending: pending,
          other: other,
          max_aging: maxAging,
          avg_aging: avgAging
        };
      }
    }

    // Convert departmentMap values to an array
    dept_compliance = Object.values(departmentMap);

    // Update totalRow based on aggregated values
    totalRow.total = dept_compliance.reduce((sum, department) => sum + department.total, 0);
    totalRow.complete = dept_compliance.reduce((sum, department) => sum + department.complete, 0);
    totalRow.inprogress = dept_compliance.reduce((sum, department) => sum + department.inprogress, 0);
    totalRow.pending = dept_compliance.reduce((sum, department) => sum + department.pending, 0);
    totalRow.other = dept_compliance.reduce((sum, department) => sum + department.other, 0);
    totalRow.max_aging = dept_compliance.reduce((sum, department) => Math.max(sum, department.max_aging), 0);
    totalRow.avg_aging = dept_compliance.length > 0 ? Math.round(dept_compliance.reduce((sum, department) => sum + department.avg_aging, 0) / dept_compliance.length) : 0;

    // Sort the array alphabetically based on the department names
    dept_compliance.sort((a, b) => a.department.toLowerCase().localeCompare(b.department.toLowerCase()));

    dept_compliance.unshift(totalRow);
    dept_compliance = dept_compliance.filter(row => {
      return Object.entries(row).some(([key, value]) => (
        key !== 'department' && value !== 0
      ));
    });
    dept_compliance.forEach((row) => {
      Object.keys(row).forEach((columnKey) => {
        if (row[columnKey] === 0 || row[columnKey] === '0') {
          row[columnKey] = '-';
        }
      });
    });
    return dept_compliance;
  } else {
    return [];
  }
};

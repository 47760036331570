import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { taskActions, getTaskBlueprintTemplates, getCurrentUser, getDateInFormat } from "../../store";
import { Form, Modal } from "../../components";
import { CloseCircleOutlined, RightSquareOutlined, CheckCircleOutlined, WarningOutlined } from "@ant-design/icons";

const TemplateFields = [
  { label: null, attribute: 'template', placeholder: "choose Template", type: 'autocomplete', allowSearch: true, iconColor: 'black'}
];

export const CreateTaskFromBlueprint = (props) => {
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const blueprintTemplates = useSelector(getTaskBlueprintTemplates.bind(null, true));
  const user = useSelector(getCurrentUser);
  const [state, setState] = useState({ selectedTemplate: null, showConfirmDialog: false });

  useEffect(() => {
    if (open) {
      dispatch(taskActions.fetchTaskBlueprintTemplates());
      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          handleCancel();
        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    } else {
      setState((_) => ({ ..._, selectedTemplate: false, showConfirmDialog: false }))
    }
  }, [open]);

  const getTemplateOptions = () => {
    let templateOptions = [];
    if (blueprintTemplates) {
      templateOptions = blueprintTemplates.map((template) => ({
        label: template.title,
        id: template.id,
        last_used: getDateInFormat(template.last_used_date) || null
      }));
    }
    return templateOptions;
  };

  const handleSubmit = (e, value) => {
    if (e) e.preventDefault();
    setState((prevState) => ({ ...prevState, showConfirmDialog: value !== undefined ? value : true }));
  };

  const handleTemplateChange = (e) => {
    setState((prevState) => ({ ...prevState, selectedTemplate: e.target.value }));
  };

  const handleConfirm = (payload) => {
    dispatch(taskActions.subscribeBlueprint(payload));
    setState((prevState) => ({ ...prevState, showConfirmDialog: false }));
    onClose();
  };
  const handleCancel = () => {
    setState((prevState) => ({ ...prevState, selectedTemplate: null }));
    onClose();
  }

  return (
    <>
      <Modal
        width={422}
        open={open}
        closeIcon={null}
        className="change-res-modal"
        actions={[
          {
            label: (
              <>
                <RightSquareOutlined className="mr1" />
                {state.selectedTemplate ? 'Proceed' : 'Next'}
              </>
            ), variant: 'lite', className: 'gap min f14 exo2 bold-600', color: '#0133CC', onClick: handleSubmit, disabled: !Boolean(state.selectedTemplate)
          },
          {
            label: (
              <>
                <CloseCircleOutlined className="mr1" />
                Close
              </>
            ), className: 'gap bg0133CC f14 exo2 bold-600', color: '#FFF', onClick: handleCancel
          },
        ]}
        title={<span className="exo2 f24 c238787">Compliance Tasks</span>}
      >
        <div className="col w-100">
          <div className="f16 reg c00085 desc">You are about to create a large number of Tasks based on your chosen Template.</div>
          {!(state.selectedTemplate) &&
            <p className="f12 mt-2"><span className="cF00 med">CAUTION! </span>It is STRONGLY recommended that you conduct a prior check that this action has not been taken earlier otherwise a lot of duplicate tasks will be generated!</p>
          }
          <div className="mt-2">
            <Form
              onSubmit={handleSubmit}
              className="row"
              formData={{ template: state.selectedTemplate }}
              Fields={TemplateFields}
              getOptions={getTemplateOptions}
              onChange={handleTemplateChange}
            />
          </div>
          {
            (state.selectedTemplate && state.selectedTemplate.last_used) &&
            <>
              <div className="cFF4444 d-flex">
                <WarningOutlined className='warning' />
                <div className="f12">
                  Compliance Tasks based on <b>{state.selectedTemplate && state.selectedTemplate.label}</b> template were created earlier! Creating new compliance tasks again may result in a lot of duplicate tasks!
                </div>
              </div>
              <p className="f14">Last used: <b>{state.selectedTemplate && state.selectedTemplate.last_used && state.selectedTemplate.last_used}</b>.</p>
              <div className="f14">Do you wish to proceed?</div>
            </>
          }
        </div>
      </Modal >
      <ConfirmModal
        selected_template_details={state.selectedTemplate}
        onConfirm={handleConfirm}
        onClose={(e) => handleSubmit(e, false)}
        open={state.showConfirmDialog}
        orgId={user && user.ent_org_id}
      />
    </>
  );
};

export const ConfirmModal = (props) => {
  const { open, selected_template_details, onConfirm, onClose, orgId } = props;

  const handleConfirmCreateTask = () => {
    let body = {
      "ent_org_id": orgId,
      "task_blueprint_id": selected_template_details.id
    };
    onConfirm(body);
  };
  useEffect(() => {
    if (open) {
      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          onClose();
        }
      };
      document.addEventListener("keydown", handleKeyDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, [open]);
  return (
    <>
      <Modal
        width={422}
        open={open}
        closeIcon={null}
        className="change-res-modal"
        actions={[
          {
            label: (
              <>
                <CheckCircleOutlined className="mr1" />
                Confirm
              </>
            ), variant: 'lite', className: 'gap min f14 exo2 bold-600', color: '#0133CC', onClick: handleConfirmCreateTask
          },
          {
            label: (
              <>
                <CloseCircleOutlined className="mr1" />
                Cancel
              </>
            ), className: 'gap bg0133CC f14 exo2 bold-600', color: '#FFF', onClick: onClose
          },
        ]}
        title={<span className="exo2 f24 c238787">Compliance Tasks</span>}
      >
        <div className="col w-100">
          <div className="f16 reg c00085 desc">Compliance Tasks for <b>{selected_template_details && selected_template_details.label}</b> will be created.
            The respective Department Responders will be notified by email.</div>
          <p className="f12 mt-2"><span className="med">NOTE: </span> It may take a few minutes for all the Tasks to be created.
            You will be notified by email when all Tasks are created.</p>
        </div>
      </Modal>

    </>
  );
};

import React, { forwardRef } from 'react';
import { PrimaryButton, Button } from "../Button";
import { Input } from "../Input";
import { SearchOutlined } from "@ant-design/icons";

const FilterSearchComponent = (props, ref) => {
  const { value, titleText } = props;
  return (
    <div className='col data-table-search'>
      <Input
        id='search'
        ref={ref}
        placeholder={`Search ${titleText}`}
        value={value || ''}
        onChange={props.onSearchChange} />
      <div className='row'>
        <PrimaryButton label='Search' labelClassName='f14 cFFF' icon={<SearchOutlined style={{ color: '#FFF' }} />} onClick={props.onSearch} />
        <Button label='Reset' className='reset' labelClassName='#00085' onClick={props.onReset} />
        <Button type='text' label='Filter' labelClassName='c1133CC' onClick={props.onFilter} />
        <Button type='text' label='Clear' labelClassName='c1133CC' onClick={props.onClear} />
      </div>
    </div>
  )
}
export const SearchComponent = forwardRef(FilterSearchComponent)
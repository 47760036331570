import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Tabs, Breadcrumb, Tooltip } from 'antd';
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { getCurrentUser, userActions, getDayjs } from "../../store";
import { TaskTracker } from "./Tracker";
import { TaskStatistics } from "./Stats";
import { CreateTaskForm } from "./CreateTask";
import { Menu, getModuleLink, PrimaryButton, IconAdd, IconUserCheck, ArcV1Icon, DownloadActionIcon } from "../../components";
import { ArcResponders } from "./Responder";
import { CreateTaskFromBlueprint } from "./BlueprintTasks";
import { ExportTaskForm } from "./ExportTaskDialog"

const MenuStyle = { color: "#1133CC", fontSize: 16 }
const MenuItems = [
  { dialogId: 'create_task', label: <span className='exo2 line-1 f15 bold-600'>Compliance Tasks<span className="exo2 f14 bold-400 d-block">(from Template)</span></span>, icon: <IconAdd className='f14 c1133CC' /> },
  { dialogId: 'responders', label: "Task Responders", icon: <IconUserCheck className='f14 c1133CC' />, },
  { dialogId: 'upload_task', label: <span className='exo2 line-1 f15 bold-600'>Custom Tasks<span className="exo2 f14 bold-400 d-block">(CSV Upload)</span></span>, icon: <UploadOutlined style={MenuStyle} />, to: 'C' },
  { dialogId: 'export_task', label: <span className='exo2 line-1 f15 bold-600'>Export Tasks<span className="exo2 f14 bold-400 d-block">(CSV Export)</span></span>, icon: <DownloadActionIcon style={MenuStyle} /> }
]
const getTab = () => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  return currentUrlParams.get('tab') || 'T';
}

export const DashboardScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const [state, setState] = useState({ tab: getTab(), menuTarget: null, selectedDialogId: null });
  const [showCreateForm, setShowCreateForm] = useState(false);

  useEffect(() => {
    const handlePopState = () => {
      const newTab = getTab();
      setState((prevState) => ({ ...prevState, tab: newTab }));
    };

    window.addEventListener("popstate", handlePopState);
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  useEffect(() => {
    if (user) {
      dispatch(userActions.fetchActiveUsers(user.ent_org_id))
    }
  }, [user])

  const getTabItems = () => {
    let tabs = [
      {
        label: <span className={`f18 exo2 bold-600 ${state.tab === 'T' ? 'c1133CC' : 'c000'}`}>Tracker</span>,
        key: 'T'
      },
      {
        label: (
          <span className={`f18 exo2 bold-600 ${state.tab === 'S' ? 'c1133CC' : 'c000'}`}>
            Stats
            {state.tab === 'S' ? (
              <Tooltip title="Export options available in Central." placement="bottomRight" >
                <InfoCircleOutlined style={{ color: '#1133CC', marginLeft: 8, fontSize: '11px' }} />
              </Tooltip>
            ) : (
              <InfoCircleOutlined style={{ color: '#777777', marginLeft: 8, opacity: 0.5, fontSize: '11px' }} disabled />
            )}
          </span>
        ),
        key: 'S'
      }
    ];
    return tabs;
  }

  const TabItems = getTabItems();
  const handleMenuItemClick = (menu) => {
    if (menu) {
      if (menu.to) {
        navigate(menu.to)
      } else if (menu.link) {
        window.location.href = menu.link
      }
    }
    setState((_) => ({ ..._, menuTarget: null, selectedDialogId: menu ? menu.dialogId : null }))
  }
  // Function to check if the user has one of the allowed roles
  const canViewMenuItems = (user) => {
    const allowedRoles = [
      'Assessor',
      'Architect',
      'Organiser',
      'Governor',
      'Administrator'
    ];
    return allowedRoles.includes(user.role.name);
  };
  const getMenus = () => {
    let menus = []
    if (canViewMenuItems(user)) {
      menus = [...MenuItems];
    }
    // if (getDayjs().isBefore(getDayjs("2024-10-30T18:30:00.000Z"))) { //enable v1 till 31st OCT 2024
    menus.push({
      key: "v1",
      icon: <ArcV1Icon className="w-100" />,
      link: getModuleLink("arcV1", user),
      label: 'Arc v1'
    })
    // }
    return menus
  }
  return (
    <div className='row v-start w-100 h-100 o-hide screen-pad-tb bgWhite dash' >
      <div className='col w-100 h-100'>
        <div className="breadcrumb screen-pad-lr">
          <Breadcrumb
            items={(state.tab === TabItems[0].key) ? [
              {
                title: (
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/?tab=T`);
                      setState({ ...state, tab: 'T' });
                    }}
                  >
                    <div className='f14 bold-400'><span className='c1133CC'>Tracker </span> /</div>
                  </a>
                )
              }


            ] :
              [
                {
                  title: (
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/?tab=S`);
                        setState({ ...state, tab: 'S' });
                      }}
                    >
                      <div className='f14 bold-400'><span className='c1133CC'>Stats </span> /</div>
                    </a>
                  )
                }
              ]
            }
          />
          {
            (state.tab === TabItems[0].key) &&
            <div className="row">
              <PrimaryButton
                label="New Task"
                icon={<IconAdd className='f14 cFFF' />}
                labelClassName='f14 cFFF bold-600 exo2'
                onClick={() => {
                  setShowCreateForm(true)
                }}
              />
              <Menu
                showArrow
                classNames={{ anchorButton: 'bg1133CC', overlay: 'custom-menu' }}
                items={getMenus()}
                onMenuClick={handleMenuItemClick} />
            </div>
          }
          {
            (showCreateForm) &&
            <CreateTaskForm open={showCreateForm} onClose={() => {
              setShowCreateForm(false)
            }} />
          }

        </div>
        <div className="col w-100 f-rest o-hide pos-relative">
          <div className="row w-100 h-btn o-hide screen-pad-lr">
            <Tabs
              items={TabItems}
              activeKey={state.tab}
              onChange={(key) => {
                setState((prevState) => ({ ...prevState, tab: key, selected: null }));
                navigate(`${window.location.pathname}?tab=${key}`);
              }}
            />
          </div>
          {(state.tab === TabItems[1].key) && <div className='w-100 shadow-box-arc'></div>}
          <div className="col w-100 f-rest o-hide  screen-pad-lr">
            <div className='col w-100 h-100 o-hide'>
              {
                (state.tab === TabItems[0].key) &&
                <TaskTracker />
              }
              {
                Boolean(TabItems[1]) && (state.tab === TabItems[1].key) &&
                <TaskStatistics />
              }
            </div>
          </div>
        </div>
        <CreateTaskFromBlueprint
          onClose={handleMenuItemClick.bind(null, null)}
          open={state.selectedDialogId === 'create_task'}
        />
        <ArcResponders
          onClose={handleMenuItemClick.bind(null, null)}
          open={state.selectedDialogId === 'responders'}
        />
        <ExportTaskForm
          onClose={handleMenuItemClick.bind(null, null)}
          open={state.selectedDialogId === 'export_task'}
        />
      </div>
    </div>
  )
}

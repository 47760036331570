import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from 'antd';

export const InfoIcon = (props) => {
    return (
        <Tooltip
            title={
                <div className='exo2 f12'>
                    Plan Date means when this Task was originally planned to be completed.
                    <br />
                    Once defined, Plan Date CANNOT be changed.
                    <br />
                    At the time of Task creation, Plan Date is the same as the Due Date.
                    <br />
                    Plan Date is used to calculate the Aging of a Task.
                </div>
            }
            trigger="click"
            placement={props.placement}
            overlayClassName={props.overlayClassName}
        >
            <InfoCircleOutlined className='info-icon' />
        </Tooltip>
    )
}
import React from 'react';
import { Dropdown } from "antd";
import { MenuButton } from "../Button";
import "./style.scss";

export const Menu = (props) => {
  const anchor = props.anchor || {}
  const classNames = props.classNames || {};
  const anchorLabel = anchor.label || '';
  const anchorIcon = anchor.icon || null;
  const anchorIconColor = anchor.iconColor;
  const items = props.items || []
  const handleContainerClick = (item, e) => {
    if (!item.closeImmediately && e && e.stopPropagation) {
      e.stopPropagation()
    }
    props.onMenuClick && props.onMenuClick(item)
  }
  const getItems = () => {
    return items.map((item, i) => {
      const icon = item.icon;
      const label = (typeof item.label === 'string') ? <span className='exo2'>{item.label}</span> : item.label;
      let content = (
        <div className='row w-100 menu-item ' key={i} onClick={handleContainerClick.bind(null, item)}>
          {Boolean(icon) && <div className='col sec-icon v-ctr h-ctr'>{icon}</div>}
          {Boolean(label) && <div className='col f-rest f16 c00085 bold-600'>{label}</div>}
        </div>
      )
      return { key: String(i), label: content }
    })
  }
  const _className = `neu-menus ${classNames.overlay || ''} ${items.length === 1 ? 'single' : ''}`
  return (
    <Dropdown
      placement={props.placement}
      arrow={props.showArrow || false}
      overlayClassName={_className}
      menu={{ items: getItems() }}
      trigger={['click']}>
      <MenuButton
        label={anchorLabel}
        icon={anchorIcon}
        iconColor={anchorIconColor}
        className={classNames.anchorButton}
        labelClassName={classNames.anchorLabel}
        onClick={(e) => e.preventDefault()} />
    </Dropdown>
  )
}

import React from "react";

export const ColorIndicator = (props) => {
  const {color_val} = props
  const getBackgroundColor = (value) => {
    switch (value) {
      case 'Emergency':
        return '#BB0000';
      case 'Urgent':
      case 'Critical':
      case 'Failed':
        return '#DD2222';
      case 'High':
        return '#FF5522';
      case 'Medium':
        return '#FFAA55';
      case 'Low':
      case 'Started':
      case 'InProgress':
        return '#FFFF88';
      case 'Minimal':
        return '#FFFFBB';
      case 'Least':
        return '#FFFFDD';
      case 'Future':
        return '#000088';
      case 'Hold':
        return '#FF6600';
      case 'Pending':
        return '#FF8888';
      case 'Open':
        return '#FFAA88';
      case 'Review':
        return '#DDFF88';
      case 'Resolved':
      case 'Remediated':
        return '#66CC66';
      case 'Complete':
        return '#22DD22';
      case 'Closed':
        return '#00BB00';
      case 'Invalid':
      case 'Duplicate':
        return '#DDDDDD';
      case 'Skipped':
      case 'Redundant':
        return '#BBBBBB';
      default:
        return '#888888';
    }
  };

  const getBorderColor = (value) => {
    switch (value) {
      case 'Emergency':
        return '#BB0000';
      case 'Urgent':
      case 'Critical':
        return '#DD2222';
      case 'High':
        return '#FF5522';
      case 'Medium':
      case 'Low':
      case 'Minimal':
      case 'Started':
      case 'InProgress':
      case 'Least':
      case 'Open':
      case 'Review':
      case 'Invalid':
      case 'Duplicate':
      case 'Skipped':
      case 'Redundant':
        return '#000000';
      default:
        return null; // No border color for default case
    }
  };

  return (
    <div
      style={{
        width: props.width? props.width:'12px',
        height: props.height? props.height:'12px',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '8px',
        backgroundColor: getBackgroundColor(color_val),
        border: `1px solid ${getBorderColor(color_val)}`
      }}
    ></div>
  );
};

import React from "react";
import "./Table.scss"
import { getCurrentUser } from "../../store";
import { useSelector } from "react-redux";
import { ColorIndicator } from '../Tasks/ColorIndicator';

export const Table = (props) => {
    const { rows, Columns, tableID, tableClassName } = props;
    const user = useSelector(getCurrentUser);
    const brandname = user.ent_org.brandname;
    // const capitalizedBrandname = brandname.charAt(0).toUpperCase() + brandname.slice(1).toLowerCase();

    return (
        <table className={`${tableClassName} stats-tbl`} id={tableID}>
            <thead>
                <tr className="table-column-row-1 ">
                    {Columns.map((col) => (
                        <th
                            key={col.label} // Added a unique key for each column
                            style={{ width: col.width || '150px' }}
                            className={`f18 exo2 bold header-cell ${col.headerClassName}`}
                        >
                            {col.colorIndicator ? (
                                <>
                                    <ColorIndicator color_val={col.label} width='14px' height='14px' />
                                    <span className="exo2" dangerouslySetInnerHTML={{ __html: col.label }}></span>
                                </>
                            ) : (
                                <span className="exo2" dangerouslySetInnerHTML={{ __html: col.label }}></span>
                            )}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>

                {
                    rows.length === 0 ? (
                        <tr className="table-row-1" >
                            <td className="f18 bold cell v-ctr h-ctr exo2">{brandname}</td>
                            <td colSpan={Columns.length} className={`f16 reg cell c238787 v-ctr h-ctr`} style={{ paddingLeft: '20px' }}>
                                No data to display!
                            </td>
                        </tr>
                    )
                        :
                        (
                            rows.map((row, i) => {
                                return (
                                    <tr key={`table-key-${tableID}-${i}`} className=''>
                                        {
                                            Columns.map((col) => {
                                                let value = row[col.attribute];
                                                let isLabelCell = col.attribute === 'label' || col.isLabel;
                                                let isIndicatorCell = col.isIndicator
                                                return (

                                                    <td key={col.attribute} className={`f18 reg cell c238787`}>
                                                        <div className={`row w-100 h-100  ${isLabelCell || isIndicatorCell ? 'v-ctr' : 'h-ctr'}`}>
                                                            {(i !== 0) && isLabelCell &&  row['labelClassName'] && (
                                                                <>
                                                                <ColorIndicator color_val={row['labelClassName']} width='14px' height='14px'  />
                                                                </>

                                                            )}
                                                            {/* for the first row, all values should be in black */}
                                                            {
                                                                (i === 0)
                                                                    ? <span className={`exo2 ${col.cellClassName} ${isIndicatorCell ? '' : 'bold'} app-color-header f18`}>{value}</span>
                                                                    : <span className={`exo2 ${col.cellClassName} ${isIndicatorCell ? '' : 'bold'} f16`}>{value}</span>
                                                            }
                                                        </div>
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        )
                }

            </tbody>
        </table>
    )
}
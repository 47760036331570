import { Table } from "./Table";
import { TableTopBar } from "./TableTopBar";
import { useSelector, useDispatch } from "react-redux";
import { getDateRangeEnums, taskActions } from "../../store"
import { useEffect, useState } from "react";

export const TableComponent = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(taskActions.fetchDateRangeEnums());
    }, [])

    const dateRangeEnumns = useSelector(getDateRangeEnums);
    useEffect(() => {
        if (dateRangeEnumns.length > 0) {
            let customDateRange = [
                { "id": "last_365_days", "label": "Last 365 Days" },
            ]
            for (let obj in dateRangeEnumns) {
                if (obj.id !== "last_365_days") {
                    customDateRange.push(obj)
                }
            }
            setDateRange(customDateRange[0])
        }
    }, [dateRangeEnumns])
    const [dateRange, setDateRange] = useState({ id: 'last_365_days', label: 'Last 365 Days' })

    useEffect(() => {
        props.onDateChange && props.onDateChange(dateRange);
    }, [dateRange])


    const handleDateChange = (value) => {
        let dateRangeLabel = ""
        if (dateRangeEnumns) {
            let filteredDateRange = dateRangeEnumns.filter((option) => option.id === value)
            if (filteredDateRange && filteredDateRange.length > 0 && filteredDateRange[0].label) {
                dateRangeLabel = filteredDateRange[0].label
            }
        }
        setDateRange({ id: value, label: dateRangeLabel })
    };

    return (
        <>
            <div className="oy-auto" style={{width:'1100px'}}>
                <div>
                    <div>
                        <TableTopBar
                            title={props.title}
                            rows={props.rows}
                            Columns={props.Columns}
                            Field={props.topBarField}
                            tableID={props.tableID}
                            selectedDateId={dateRange.id}
                            selectedDateLabel={dateRange.label}
                            handleFieldChange={handleDateChange}
                            options={dateRangeEnumns}
                        />
                    </div>
                    <div style={{overflow: 'auto' }}>
                        <div className="inc-stats">
                            <Table
                                title={props.title}
                                rows={props.rows}
                                Columns={props.Columns}
                                Field={props.tableField}
                                tableID={props.tableID}
                                tableClassName={props.tableClassName}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

